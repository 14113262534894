$(document).ready(function () {

    let $uiList = $('ul.statuspage-ui');
    let $componentList = $('ul.statuspage-component');

    //Set Order
    let currentUiOrderStr = $uiList.attr("data-ui-order");
    if(currentUiOrderStr){
        let currentUiOrder = $.parseJSON(currentUiOrderStr);
        for(var uiComponent of currentUiOrder){
            let componentElement = $("[data-ui="+uiComponent+"]", $componentList).detach();
            $uiList.append(componentElement);
        }
    }


    //Configure Sortable and Draggable UI
    $('ul.statuspage-ui li, ul.statuspage-component li')
    $uiList.sortable({
        revert: 'invalid',
        connectWith: "ul.statuspage-component",
        items: "li.component",
        placeholder: "component-placeholder",
        update: (e, ui) => {
            var ui_order = [];
            $('ul.statuspage-ui li').each((i, el) => {
                ui_order.push($(el).data("ui"));
            });
            $.ajax({
                url: '/customize/ui-order',
                type: 'PUT',
                data: {
                    "ui_order": ui_order
                },
                dataType: "json",
                success: (result) => {
                    notify(result.message, result.type);
                }
            });
        }
    }).disableSelection();
    $componentList.sortable({
        revert: 'invalid',
        connectWith: "ul.statuspage-ui",
        items: "li.component",
        placeholder: "component-placeholder",
    }).disableSelection();

});
