// Handle Editor Toggle and Service Delete
+function($, window){ 'use strict';

    //Document Ready
    $( () => {

        let serviceWrapper = $("#serviceDashboard");
        let serviceSelects = $("select[name='status']");

        serviceSelects.change(function(e){
            let $this = $(e.currentTarget);
            let service_id = $this.data("slug");
            let status_type = $this.find(':selected').attr("data-status-type");
            let url = '/service/' + service_id + '/status';
            let status = $this.val();
            $.ajax({
                url: url,
                type: 'PUT',
                data: {
                    "status": status
                },
                dataType: "json",
                success: (result) => {
                    let service = $(".service-content[data-slug="+service_id+"]");
                    service.attr("data-status-type", status_type);
                    notify(result.message, result.type);
                }
            });
        });

        //Handle when edit button in service is clicked
        $("a.edit", serviceWrapper).click( e => {
        	e.preventDefault();
            var $this = $(e.currentTarget);
            let slug = $this.data("slug");
        	let serviceContent = $(".service-content[data-slug='"+slug+"']");
        	let serviceEdit = $(".service-edit[data-slug='"+slug+"']");
        	serviceContent.hide();
        	serviceEdit.fadeIn();
        });

        //Handle when cancel button in editor is clicked
        $("a.cancel", ".service").click( e => {
        	e.preventDefault();
            var $this = $(e.currentTarget);
            let slug = $this.data("slug");
            let serviceContent = $(".service-content[data-slug='"+slug+"']");
        	let serviceEdit = $(".service-edit[data-slug='"+slug+"']");
        	serviceEdit.hide();
        	serviceContent.fadeIn();
        });

        //Handle when delete button in service is clicked
        $("a.delete", ".service").click( e => {
        	e.preventDefault();
            var $this = $(e.currentTarget);
            let slug = $this.data("slug");
        	let res = confirm("Are you sure to delete this incident? All data will be lost!");
        	if(res){
        		var deleteForm = $("form.delete[data-slug='"+slug+"']");
        		deleteForm.submit();
        	}
        });

    });

}(jQuery, window);


//Handle Service re-ordering
+function($, window){ 'use strict';

    //Document Ready
    $( () => {

        let listGroup = $(".list-group", "#serviceDashboard");
        if(!listGroup) return;
        listGroup.nestedSortable({
            handle: ".fa-bars",
            items: 'li',
            toleranceElement: '> div.row-group',
            listType: 'ul',
            maxLevels: 2,
            placeholder: "list-group-item-placeholder",
            update: (event, ui) => {
                var service_ids = [];
                $(".list-group > .list-group-item").each(function(i, el){
                    let parent = $(el);
                    let child = $(".list-group-item", parent);

                    let parent_slug = parent.data("slug");
                    var child_slugs = [];

                    // Handle setting has-child class to parent list-group-item, child service's class is ignored
                    if(child.length > 0){
                        parent.addClass("list-group-item--has-child");
                    }else{
                        parent.removeClass("list-group-item--has-child");
                    }

                    // Add child service slug into an array for re-ordering
                    $(".list-group-item", parent).each(function(i, elc){
                        var child_slug = $(elc).data("slug");
                        child_slugs.push(child_slug);
                    });

                    service_ids.push({parent: parent_slug, child: child_slugs});
                });

                $.ajax({
                    url: "/services/order",
                    type: 'PUT',
                    data: {
                        "services": service_ids
                    },
                    dataType: "json",
                    success: (result) => {
                        notify(result.message, result.type);
                    }
                });

            }
        });
    });

}(jQuery, window);
