// Global Function Alert Bar
let alertBar = $("#alert.alert");
let alertBarMessage = $("#alert-message", alertBar);
function notify(msg, type){
    alertBar.removeClass("alert-danger alert-success alert-info");
    alertBar.addClass("alert-"+type);
    alertBarMessage.text(msg);
    alertBar.fadeIn();
    setTimeout(function(){
        alertBar.fadeOut();
    }, 5000);
}
