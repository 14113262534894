+function($, window){ 'use strict';

    //Document Ready
    $( () => {
        //Universial Clickable link on data-href attr
        $('[data-href]').on("click", function() {
            document.location = $(this).data('href');
        });

        //Bootstrap Tooltip
        $('[data-toggle="tooltip"]').tooltip();

        //AJAX token
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        //Universial toggle edit editor fade in
        $('a.edit[data-form]').on("click", (e) => {
            e.preventDefault();
            let $this = $(e.currentTarget);
            let form = $this.data("form");
            if(form){
                $(form+"Inverse").hide();
                $(form).fadeIn();
            }
        });

        //Universial toggle edit editor fade out
        $('a.cancel[data-form]').on("click", (e) => {
            e.preventDefault();
            let $this = $(e.currentTarget);
            let form = $this.data("form");
            if(form){
                $(form).hide();
                $(form+"Inverse").fadeIn();
            }
        });

        //Universial toggle edit editor fade out
        $('a.delete[data-form]').on("click", (e) => {
            e.preventDefault();
            let $this = $(e.currentTarget);
            let form = $this.data("form");
            let message = $this.data("message");
            if(form && message && confirm(message)){
                $(form).submit();
            }
        });
    });

}(jQuery, window);
