+function($, window){ 'use strict';

    //Document Ready
    $( () => {

        let eventCreate = $("#createEvent");
        let serviceStatus = $("#serviceStatus");
        let form = $("form", eventCreate);

        // Reload the incidents page to display newly created incident
        serviceStatus.on('hidden.bs.modal', e => {
            e.preventDefault();
            window.location.reload();
            window.location.href = window.location.href;
        });

        // Prevent create incident form get submitted so that we cna submit it in ajax
        $("input[type=submit]", form).click( e => {
            e.preventDefault();
            let $this = $(e.currentTarget);
            let formData = form.serialize();
            $this.addClass("disabled").prop('disabled', true);

            $.ajax({
                type: "POST",
                url: "/incident",
                data: formData,
                success: (res) => {
                    if(res && res.message){
                        notify(res.message, 'success');
                        eventCreate.modal("hide");

                        let title = serviceStatus.attr("data-translate-title");
                        let buttonText = serviceStatus.attr("data-translate-button-text");
                        swal({
                            title: res.message,
                            text: title,
                            type: "info",
                            showCancelButton: true,
                            confirmButtonColor: "#68BC43",
                            confirmButtonText: buttonText,
                            cancelButtonText: "No",
                            closeOnConfirm: false,
                            closeOnCancel: false
                        }, isConfirm => {
                            if(isConfirm){
                                swal.close();
                                serviceStatus.modal("show");
                            }else{
                                // Reload the incidents page to display newly created incident
                                window.location.reload();
                                window.location.href = window.location.href;
                            }
                        });
                    }else{
                        notify('Unexpected Error', 'danger');
                        $this.removeClass("disabled").prop('disabled', false);
                    }
                },
                error: (err) => {
                    $this.removeClass("disabled").prop('disabled', false);
                    if(err.responseJSON && err.responseJSON.message){
                        notify(err.responseJSON.message, 'danger');
                    }
                }
            });
        });


    });
}(jQuery, window);
